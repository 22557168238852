'use strict';
import '../../styles/9-forjs/_sitePreloader.scss';
import { renderWaves } from "../wavesAnimation.js";

// function docReady(fn) {
//     // see if DOM is already available
//     if (document.readyState === "complete" || document.readyState === "interactive") {
//         // call on next available tick
//         setTimeout(fn, 1);
//     } else {
//         console.log('waiting')
//         document.addEventListener("DOMContentLoaded", fn);
//     }
// }  

// docReady(function() {
//     let fpSlider = document.getElementById("fp-waves-preloader");
//     if(fpSlider){
//         renderWaves(fpSlider);
//     }
// });

function waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                observer.disconnect();
                resolve(document.querySelector(selector));
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

waitForElm('#fp-waves-preloader').then((element) => {
    renderWaves(element);
});