import SineWaves from 'sine-waves';

export let renderWaves = (element) => {
    var waves = new SineWaves({
        el: element,
        speed: 5,
        
        ease: 'SineInOut',
        
        wavesWidth: '80%',
        
        waves: [
          { /* small-set wave 1 */
            timeModifier: 4,
            lineWidth: 1,
            amplitude: -25,
            wavelength: 25
          },
          { /* small-set wave 2 */
            timeModifier: 3,
            lineWidth: 1,
            amplitude: -20,
            wavelength: 25
          },
              { /* small-set wave 3 */
            timeModifier: 3,
            lineWidth: 1,
            amplitude: 40,
            wavelength: 40
          },
          { /* small-set wave 4 tall */
            timeModifier: 2,
            lineWidth: 1.5,
            amplitude: -50,
            wavelength: 60
          },
          { /* medium-set wave */
            timeModifier: 0.8,
            lineWidth: 1.8,
            amplitude: -210,
            wavelength: 140
          },
          { /* large-set wave */
            timeModifier: 0.4,
            lineWidth: 2,
            amplitude: -260,
            wavelength: 200
          }
        ],
       
        // Called on window resize
        resizeEvent: function() {
          var gradient = this.ctx.createLinearGradient(0, 0, this.width, 0);
          gradient.addColorStop(0,"#D93E18");
          gradient.addColorStop(0.3,"#D91D18");
          gradient.addColorStop(0.4,"#D96018");
          gradient.addColorStop(0.6,"#D9187B");
          gradient.addColorStop(0.8,"#D98218");
          gradient.addColorStop(1,"#D93E18");
          
          var index = -1;
          var length = this.waves.length;
            while(++index < length){
            this.waves[index].strokeStyle = gradient;
          }
          
          // Clean Up
          index = void 0;
          length = void 0;
          gradient = void 0;
        }
      });
}